module.exports = {
  slideOnePhoto: require("./images/AdobeStock_218995920.png"),
  slideTwoPhoto: require("./images/AdobeStock_400148609.png"),
  slideThreePhoto: require("./images/AdobeStock_109179300.png"),
  typesOnePhoto: require("./images/VENDINGMACHINE 2.png"),
  typesTwoPhoto: require("./images/IVM-newUI_IVM_Antracit_Curent_Transparent.png"),
  typesThreePhoto: require("./images/Vending Window Display + Screen.png"),
  effectOne: require("./images/AdobeStock_221152595.png"),
  downloadOne: require("./images/Mockup@2x.png"),
  appsDownload: require("./images/Group 20.png"),
  contactPhoto: require("./images/contact.png"),
};
