import React from "react";
import styles from "./Contact.module.css";

const ContactComponent = () => {
  return (
    <div className={styles.contact}>
      <div className="container">
        <div className="row">
          <div className={`offset-5 col-lg-7`}>
            <h3 className={styles.contactHeading}>Contact Us</h3>
            <p className={styles.contactParagraph}>
              If you’re ready to see how we can support your
              <br /> company, book a call to chat with our sales team or <br />{" "}
              request a customized solution
            </p>
            <div>
              <form>
                <div
                  className={`${styles.groupOne} d-flex flex-wrap justify-content-between`}
                >
                  <div className="d-flex flex-column">
                    <label>Full Name</label>
                    <input type="text" />
                  </div>
                  <div className="d-flex flex-column">
                    <label>Company Name</label>
                    <input type="text" />
                  </div>
                </div>
                <div
                  className={`${styles.groupTwo} d-flex flex-wrap justify-content-between`}
                >
                  <div className="d-flex flex-column">
                    <label>Email Address</label>
                    <input type="email" />
                  </div>
                  <div className="d-flex flex-column">
                    <label>Phone Number</label>
                    <input type="text" />
                  </div>
                </div>
                <div
                  className={`${styles.contactButton} d-flex justify-content-end`}
                >
                  <button>Contact Us</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactComponent;
