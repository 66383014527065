import React, { useState, useEffect } from "react";
import styles from "./sliders.module.css";
import { slideOnePhoto, slideThreePhoto, slideTwoPhoto } from "../../assets";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const Sliders = ({ onTypes, currentIndex, finalWidth }) => {
  let [index, setIndex] = useState(currentIndex);
  let [progressWidth, setProgressWidth] = useState(finalWidth || 33);

  const PAGES = [
    {
      id: 1,
      heading: "About Us",
      paragraph: `We are changing how consumers shop by offering, innovative state of the art e vending and retail solutions that merge digital with physical in an easy and seamless fashion. A change with a sole mission of bringing an easier, more convenient shopping solution to consumers and business partners at the same time. Care to join us?`,
      isHaveSpecialActions: false,
      Image: slideOnePhoto,
    },
    {
      id: 2,
      heading: "Our Solutions",
      paragraph: `
            We customize our product for specific business needs. Based on each business need, we make sure we have the right solution from a hardware and software to ensure our partners objectives are met. Whilst we show case some of them, please feel free to reach out so we can understand how best to serve your needs!
            `,
      isHaveSpecialActions: true,
      Image: slideTwoPhoto,
    },
    {
      id: 3,
      heading: "Key Features",
      paragraph: ` 
            Various solutions including two way interactive screens with real time data connectivity, enabling business partners to monitor and control their products in terms of supply, pricing and marketing opportunities!            `,
      isHaveSpecialActions: false,
      Image: slideThreePhoto,
    },
  ];
  const nextSlide = () => {
    if (index !== 2) {
      setIndex(++index);
    }
    forwardProgress();
  };
  const previous = () => {
    if (index !== 0) {
      setIndex(--index);
    }
    backwardProgress();
  };
  const forwardProgress = () => {
    if (index === 2) {
      setProgressWidth(100);
    } else if (index > 0) {
      setProgressWidth(progressWidth + 33);
    }
  };

  const backwardProgress = () => {
    if (index > 0) {
      setProgressWidth(progressWidth - 33);
    } else if (index === 0) {
      setProgressWidth(33);
    }
  };
  const onSelectingTypes = () => {
    onTypes(true);
  };
  return (
    <div className="container">
      <div className={`${styles.sliderContainer}`}>
        <div className="row align-items-end">
          <div className={`${styles.sliderInfo} col-lg-4`}>
            <h4 className={`${styles.sliderHeaders} slider-headers`}>
              {PAGES[index].heading}
            </h4>
            <p className={`${styles.sliderParagraph}`}>
              {PAGES[index].paragraph}
            </p>

            {PAGES[index].isHaveSpecialActions && (
              <div className={styles.types} onClick={onSelectingTypes}>
                <span>See More</span>
                <IoIosArrowForward color="#13003E" fontSize={20} />
              </div>
            )}
          </div>
          <div className="offset-lg-1 col-lg-7">
            <img
              src={PAGES[index].Image}
              loading="lazy"
              alt="slider"
              className="img-fluid"
            />
          </div>
        </div>
        <div className={`${styles.indicators} d-flex justify-content-between`}>
          <div className="d-flex align-items-center">
            <IoIosArrowBack
              color="#13003E"
              fontSize={20}
              className="me-2"
              onClick={previous}
            />
            <span>{PAGES[index].id}/3</span>
          </div>
          <IoIosArrowForward
            color="#13003E"
            fontSize={20}
            onClick={nextSlide}
          />
        </div>
        <div className={`${styles.sliderLine}`}>
          <div
            className={styles.beforePseudoElement}
            style={{ width: progressWidth + "%" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Sliders;
