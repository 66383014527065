import React from "react";
import styles from "./navbar.module.css";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="navbar-brand">
          <h2
            style={location.pathname === "/contact" ? { color: "white" } : {}}
          >
            VNDG.ME
          </h2>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className={styles.navLinks}>
            <li className={styles.navSingleLink}>
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            {/* <li className={styles.navSingleLink}>
                    <Link className='nav-link' to='/app'>
                      Application
                    </Link>
                  </li> */}
            <li className={styles.navSingleLink}>
              <Link className="nav-link" to="/contact">
                <span className={styles.modifyLastLetter}>Contact Us</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
