import React, { useState } from "react";
import styles from "./types.module.css";
import { typesOnePhoto, typesThreePhoto, typesTwoPhoto } from "../../assets";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const Types = ({ onUsingEffect, onSwitchBack }) => {
  let [index, setIndex] = useState(0);
  const PAGES = [
    {
      id: 1,
      heading: "CHANNEL SPECIFIC",
      paragraph: `
            Be it a mall, office building, airport or other commercial and government buildings our solutions are size specific and have a full range of temperature control offered to best suit each venue.            `,
      Image: typesOnePhoto,
      imgClass: "effect1",
    },
    {
      id: 2,
      heading: "RETAIL SPECIFIC",
      paragraph: `
            In line with our fit for purpose approach we also provide a wide range of products that are retail specific enabling either a superior in store solution and or helping in innovative supply chain solutions...want to know more? get in touch!            `,
      Image: typesTwoPhoto,
      imgClass: "effect2",
    },
    {
      id: 3,
      heading: "EVENTS AND OUTDOORS",
      paragraph: `We understand how difficult it may be to find the right solution for pop up or seaonsal events. Coupled with a relatively harsh environment we ensure that we package our solutions in a way to make them easy to manage and withstand high temperatures.`,
      Image: typesOnePhoto,
      imgClass: "effect3",
    },
    // },
    // {
    //     id:4,
    //     heading: 'Glass Front Machine',
    //     paragraph:`These machines offer a clear view, enticing customers with a visual display of products. Their transparent design enhances convenience and selection, with also a lift to facilitate the delivery of fragile products`,
    //     Image: typesThreePhoto
    // }
  ];
  const changingMachines = (param) => {
    if (param === "back") {
      setIndex(--index);
    } else if (param === "forward") {
      setIndex(++index);
    }
  };

  const BackToNextSlides = (param) => {
    onSwitchBack(false, param);
  };

  return (
    <div className={`${styles.typesContainer}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className={`${styles.typesInfo} col-lg-4`}>
            <div className="d-flex">
              <div className="d-flex">
                {index > 0 && (
                  <IoIosArrowBack
                    className={styles.backIcon}
                    color="#13003E"
                    fontSize={24}
                    onClick={() => changingMachines("back")}
                  />
                )}
                <h4 className={`${styles.typesHeaders}`}>
                  {PAGES[index].heading}
                </h4>
              </div>
              <div>
                {index <= 1 && (
                  <IoIosArrowForward
                    className={`${styles.forwardIcon} ms-5`}
                    color="#13003E"
                    fontSize={24}
                    onClick={() => changingMachines("forward")}
                  />
                )}
              </div>
            </div>

            <p className={`${styles.typesParagraph} mb-4`}>
              {PAGES[index].paragraph}
            </p>
            {/* <div className={`${styles.typesView} d-flex align-items-center`}>
                        <span>View All</span>
                        <IoIosArrowForward  color='#13003E' fontSize={20} className='ms-3' />
                    </div> */}
          </div>
          <div
            className={`${styles[PAGES[index].imgClass]} offset-lg-3 col-lg-5`}
          >
            <img src={PAGES[index].Image} alt="slider" />
          </div>
        </div>
        <div className={`${styles.indicators} d-flex justify-content-between`}>
          <div className="d-flex align-items-center">
            <IoIosArrowBack
              color="#13003E"
              fontSize={20}
              className="me-2"
              onClick={() => BackToNextSlides("back")}
            />
            <span>2/3</span>
          </div>
          <IoIosArrowForward
            color="#13003E"
            fontSize={20}
            onClick={() => BackToNextSlides("forward")}
          />
        </div>
        <div className={`${styles.typesLine}`}>
          <div
            className={styles.beforePseudoElement}
            style={{ width: 66 + "%" }}
          ></div>
        </div>{" "}
      </div>
    </div>
  );
};

export default Types;
