import React from "react";
import Navbar from "../../components/Navbar/navbar";
import { Outlet } from "react-router-dom";
import Loading from "../../components/LoadingScreen/loading";

const Root = () => {
  return (
    <div>
      <Navbar />
      <Loading />
      <Outlet />
    </div>
  );
};

export default Root;
