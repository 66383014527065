import React, { useState } from "react";
import Loading from "../../components/LoadingScreen/loading";
import Navbar from "../../components/Navbar/navbar";
import Sliders from "../../components/Sliders/sliders";
import Types from "../../components/Types/types";

const Home = () => {
  let [isTypesVisibile, setIsTypesVisible] = useState(false);
  let [currentIndex, setCurrentIndex] = useState(0);
  let [finalWidth, setFinalWidth] = useState(0);

  const onSelectingTypes = (type) => {
    setIsTypesVisible(type);
  };

  const onSelectingSliders = (type, param) => {
    console.log(type);
    console.log(param);
    if (param === "back") {
      setIsTypesVisible(type);
      setCurrentIndex(0);
      setFinalWidth(33);
    } else if (param === "forward") {
      setIsTypesVisible(type);
      setCurrentIndex(2);
      setFinalWidth(100);
    }
  };
  return (
    <div>
      {!isTypesVisibile && (
        <Sliders
          onTypes={onSelectingTypes}
          currentIndex={currentIndex}
          finalWidth={finalWidth}
        />
      )}
      {isTypesVisibile && <Types onSwitchBack={onSelectingSliders} />}
    </div>
  );
};

export default Home;
