import React, { useState, useEffect, useRef } from "react";
import styles from "./loading.module.css";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const Loading = () => {
  const [isLoading, setIsLoading] = useState(false);
  const scrollableDivRef = useRef(null);

  const handleScroll = () => {
    setIsLoading(true);
  };
  useEffect(() => {
    const divElement = scrollableDivRef.current;
    if (divElement) {
      divElement.addEventListener("wheel", handleScroll);
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);
  return (
    <div
      className={styles.loading}
      style={isLoading ? { top: "-100%" } : { top: "0%" }}
    >
      <div className={styles.scrollableContent} ref={scrollableDivRef}>
        <div className={styles.loadingHeader}>
          <h1 className="">VNDG.ME</h1>
        </div>
        <div
          className={`${styles.loadingScroll} text-fluid`}
          onClick={handleScroll}
        >
          <span className="text-fluid">Discover more</span>
          <MdOutlineKeyboardArrowDown
            className={styles.arrowdwn}
            fontSize={32}
          />
        </div>
      </div>
    </div>
  );
};

export default Loading;
